import _export from "../internals/export";
import _global3 from "../internals/global";
import _task from "../internals/task";
var $ = _export;
var _global2 = _global3;
var clearImmediate = _task.clear; // `clearImmediate` method
// http://w3c.github.io/setImmediate/#si-clearImmediate

$({
  global: true,
  bind: true,
  enumerable: true,
  forced: _global2.clearImmediate !== clearImmediate
}, {
  clearImmediate: clearImmediate
});
export default {};