import _export from "../internals/export";
import _global3 from "../internals/global";
import _task from "../internals/task";
var $ = _export;
var _global2 = _global3;
var setImmediate = _task.set; // `setImmediate` method
// http://w3c.github.io/setImmediate/#si-setImmediate

$({
  global: true,
  bind: true,
  enumerable: true,
  forced: _global2.setImmediate !== setImmediate
}, {
  setImmediate: setImmediate
});
export default {};